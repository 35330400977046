import { DataModel } from "./DataModel";

export class MedData extends DataModel {
  // constants
  notEditable = ["ePVS", "CKD-EPI", "CHA2DS2-VA", "BMI", "congestion score"];

  constructor(data, canShowHistory = false) {
    super(data, canShowHistory);
    this.hasHistory = true;
    this.sortBy = ["measurement_datetime", "measurement_type"];
    this.sortByNames = ["Measured", "Type"];
    this.dateTimeFields = ["measurement_datetime", "created_at"];
    this.stringFields = ["measurement_type"];
    this.editable = ["measurement_datetime", "measurement_value", "comment"];
    this.initSort = [true, null];
    this.canSwitchSortOrder = true;
  }
}
