import { apiGetPatients } from "../api";
import { PatientDto, ApiConnector } from "../apiConnector";
import { APIDataModel } from "./APIDataModel";
import {
  canApprovePaperConsent,
  canRevokePaperConsent,
  consentOk,
} from "../dataUtils";
import { SeerlinqDRIHeartCore } from "../quickLookAnalyses";

export class Patients extends APIDataModel<PatientDto> {
  constructor(
    public api: ApiConnector,
    public pageSize: number,
  ) {
    super(api, pageSize);
    this.sortBy = ["patient_id", "created_at"];
    this.sortByNames = ["Patient HF study ID", "Added"];
    this.dateTimeFields = ["created_at", "last_ppg"];
    this.dateFields = ["date_of_birth"];
    this.initSort = [null, true];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = [
      "patient_state",
      "patient_status",
      "realm",
      "sex",
      "informed_consent",
    ];
    this.filteringListAttrs = ["patient_study"];
    this.filteringIntAttrs = ["alerts"];
    this.editable = ["patient_state", "monitoring_note"];
    this.queryKey = "patient_id";

    this.paginated = true;
  }

  // data initialization
  dataInit(heartCoreInit: boolean = true) {
    this.data = this.data.map((item) => {
      const addedBy = item.user.username;
      return { ...item, ["added_by"]: addedBy };
    });
    if (this.api.amILevel3 && heartCoreInit) {
      this.initHeartCore();
    }
    super.init();
  }

  initHeartCore() {
    this.data = this.data.map((item) => {
      const patHeartCore = new SeerlinqDRIHeartCore(this.api);
      let lastPPG: string = null;
      let canRunHC = false;

      if ("heart_core" in item) {
        lastPPG = item.heart_core?.last_ppg ?? null;
        canRunHC = item.heart_core?.can_run_heartcore ?? false;
      }

      return {
        ...item,
        ["last_ppg"]: lastPPG,
        ["canRunHC"]: canRunHC,
        ["patHeartCore"]: patHeartCore,
      };
    });
  }

  // data fetching
  async init(heartCoreInit: boolean = true, loadAllPages: boolean = false) {
    await this.fetchFirstPage();
    this.dataInit(heartCoreInit);
    this.firstPage = true;
    if (loadAllPages) {
      await this.fetchNextPage(heartCoreInit, true);
    }
  }

  async fetchFirstPage() {
    if (this.apiPage > 1) {
      return null;
    }
    const page = await this.fetchPage();
    // set details
    this.apiTotalPages = page.pagination.total_pages;
    this.apiTotalItems = page.pagination.total_items;
    this.data = page.patients;
  }

  async fetchNextPage(heartCoreInit: boolean, recursive: boolean = false) {
    if (this.apiPage < this.apiTotalPages) {
      this.apiPage++;
      const page = await this.fetchPage();
      this.data = [...this.data, ...page.patients];
      this.dataInit(heartCoreInit);
    } else {
      this.fullyLoaded = true;
    }
    if (recursive && !this.fullyLoaded) {
      await this.fetchNextPage(heartCoreInit, true);
    }
  }

  // other stuff
  async editRisk(patId: number, field: object) {
    await this.api.put(`patients/${patId}`, field);
    window.location.reload();
  }

  async editMonNote(patId: number, field: object) {
    await this.api.put(`patients/${patId}`, field);
    window.location.reload();
  }

  canApprovePaperConsent(patient) {
    return canApprovePaperConsent(patient);
  }

  canRevokePaperConsent(patient) {
    return canRevokePaperConsent(patient);
  }

  consentOk(patient) {
    return consentOk(patient);
  }

  disabledPatLink(patient) {
    return !this.consentOk(patient) && !this.api.amIAdmin;
  }

  private async fetchPage() {
    const res = await apiGetPatients({
      query: {
        load_type: "basic",
        page: this.apiPage,
        page_size: this.pageSize,
        sort_by: ["created_at"],
      },
      throwOnError: true,
    });
    return {
      patients: res.data.patients as PatientDto[],
      pagination: res.data.pagination,
    };
  }
}
