import { DataModel } from "./DataModel";
import { dayNames } from "../dataUtils";

export class Schedules extends DataModel {
  constructor(data) {
    super(data);
    this.dateFields = ["start_date"];
  }

  schedDays(member) {
    return member.schedule_frequency_on_days
      .map((day) => dayNames[day])
      .join("/");
  }

  schedString(member) {
    const ordinalSuperscript = { 1: "st", 2: "nd", 3: "rd" };
    if (member.schedule_frequency_regular_repeat_every != null) {
      const ordinalStr =
        ordinalSuperscript[
          parseInt(member.schedule_frequency_regular_repeat_every)
        ] || "th";
      var freqString =
        "Every " +
        member.schedule_frequency_regular_repeat_every +
        ordinalStr +
        " day";
    } else {
      var freqString = "Days: " + this.schedDays(member);
    }
    freqString += " at times: " + member.schedule_times.join(", ");
    return freqString;
  }
}
