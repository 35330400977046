import { ApiConnector } from "../apiConnector";
import { DataAdd } from "./DataAdd";
import { parseDatetimeToLocal } from "../utils";

export class AddBasics extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "weight",
      "dry weight",
      "temperature",
      "respiratory rate",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
    ];
    this.desc = "Patient laboratory data";
    this.dateTimeFields = ["measurement_datetime"];
    this.bodyField = "medical_data";
    this.endpoint = "hf/data";
    this.defaultDateTimeName = "Patient added to our DB";
    this.defaultDateTimeFields = ["measurement_datetime"];
    this.reloadToTab = "basics";
    this.requiredFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_value",
    ];
  }

  initEmpty() {
    this.defaultDateTime = parseDatetimeToLocal(this.patient.data.created_at);
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        measurement_datetime: this.defaultDateTime,
        measurement_type: variable,
        measurement_unit: this.getUnits(variable, true),
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}
