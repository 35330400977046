import { ApiConnector } from "../apiConnector";
import { DataAdd } from "./DataAdd";

export class AddSymptoms extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = ["shortness of breath", "fatigue score"];
    this.initFields = [
      "symptom_name",
      "symptom_date",
      "symptom_change_in_last_six_m",
    ];
    this.desc = "HF-related symptoms";
    this.bodyField = "symptoms";
    this.endpoint = "hf/symptoms";
    this.defaultDateTimeName = "Today";
    this.defaultDateTimeFields = ["symptom_date"];
    this.requiredFields = [
      "symptom_name",
      "symptom_date",
      "symptom_value",
      "symptom_change_in_last_six_m",
    ];
    this.reloadToTab = "symptoms";
  }

  initEmpty() {
    this.defaultDateTime = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        symptom_date: this.defaultDateTime,
        symptom_name: variable,
        symptom_change_in_last_six_m: false,
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}
