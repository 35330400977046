import { ApiConnector } from "../apiConnector";
import { DataAdd } from "./DataAdd";

export class AddUser extends DataAdd {
  // constants
  allRoles = [
    "patient-ppg-app",
    "physician-ppg-app",
    "study-ppg-app",
    "patient",
    "physician",
    "study-physician",
    "seerlinq-user",
  ];

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      username: null,
      email: null,
      password: null,
      role: null,
      preferred_language: "sk",
      connected_patient_id: null,
      managed_patient_ids: [],
    };
  }

  async initEmpty() {}
  async post(connected = null, managed = []) {
    this.addingList["connected_patient_id"] = connected;
    this.addingList["managed_patient_ids"] = managed;
    try {
      const response = await this.api.post("users", this.addingList);
      if (response != null) {
        window.PineconeRouter.context.navigate("/admin/users");
        location.reload();
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}
