import { DataModel } from "./DataModel";

export class Clinics extends DataModel {
  constructor(data) {
    super(data);
    this.sortBy = ["name"];
    this.sortByNames = ["Name"];
    this.initSort = [false];
    this.dateTimeFields = ["created_at"];
    this.canSwitchSortOrder = false;
    this.filteringAttrs = ["realm", "country"];
    this.filteringIntAttrs = ["numPhysicians"];
    this.editable = [
      "name",
      "ambulance",
      "address",
      "country",
      "realm",
      "phone",
      "email",
      "physicians",
    ];

    this.rowsPerPage = 10;
  }

  init() {
    this.data = this.data.map((item) => {
      const numPhysicians = item.physicians.length;
      return { ...item, ["numPhysicians"]: numPhysicians };
    });
    super.init();
  }
}
