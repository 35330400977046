export class ListDataField {
  addList: any[] = [];
  removeList: any[] = [];
  constructor() {}

  remove(item: any, removeFrom: any = null, index: number = null) {
    this.removeList.push(item);
    if (removeFrom != null) {
      removeFrom.splice(index, 1);
    }
  }

  add(item: any, addTo: any = null) {
    this.addList.push(item);
    if (addTo != null) {
      addTo.push(item);
    }
  }

  finalList(originalList: any[] = []) {
    const finalList = [...originalList];
    this.addList.forEach((item) => {
      if (!finalList.includes(item)) {
        finalList.push(item);
      }
    });
    this.removeList.forEach((item) => {
      const index = finalList.indexOf(item);
      if (index !== -1) {
        finalList.splice(index, 1);
      }
    });
    return finalList;
  }
}
