import { ApiConnector } from "../apiConnector";
import { DataAdd } from "./DataAdd";
import { Physicians } from "../dataModels/Physicians";
import { Users } from "../dataModels/Users";
import { ModalWindow } from "../modals";

export class AddPatient extends DataAdd {
  createUser = false;
  dataUser = { preferred_language: "sk", token_expiry_timedelta: "P1D" };

  physiciansModal: ModalWindow = null;
  usersModal: ModalWindow = null;

  // constants
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      patient_study: [null],
      date_of_birth: null,
      sex: null,
      height: null,
      description: null,
      realm: 1,
      race: "Unknown / Not Reported",
      name: null,
      residence: "N/A",
      health_insurance: null,
      id_number: null,
      email: null,
      phone: null,
      append_to_physicians: [],
      append_to_physician_users: [],
    };
  }

  async initEmpty() {
    if (this.api.amILevel3) {
      const users = new Users(this.api);
      await users.initPhysiciansOnly();
      this.usersModal = new ModalWindow(users);
      await this.usersModal.init("/static/templates/modals/users.html");

      const physiciansResponse = await this.api.get("physicians");
      const physicians = new Physicians(physiciansResponse.physicians);
      physicians.init();
      this.physiciansModal = new ModalWindow(physicians);
      await this.physiciansModal.init(
        "/static/templates/modals/physicians.html",
      );
    }
  }

  choosePhysiciansForPatient() {
    this.physiciansModal.modalData.filter["realm"] = [this.addingList.realm];
    this.physiciansModal.modalText = "Choose physicians for a new patient";
    this.physiciansModal.chooseSingle = false;

    this.physiciansModal.openModal();
  }

  physiciansLength() {
    if (this.api.amILevel3) {
      return this.physiciansModal.finalMultiChoice.length;
    } else {
      return null;
    }
  }

  usersLength() {
    if (this.api.amILevel3) {
      return this.usersModal.finalMultiChoice.length;
    } else {
      return null;
    }
  }

  chooseUsersForPatient() {
    this.usersModal.modalText = "Choose managing users for a new patient";
    this.usersModal.chooseSingle = false;

    this.usersModal.openModal();
  }

  async post(physicians: string[] = [], users: string[] = []) {
    this.addingList["append_to_physicians"] = physicians;
    this.addingList["append_to_physician_users"] = users;
    try {
      if (this.createUser) {
        var response = await this.api.post("patients/patient-and-user", {
          ...this.addingList,
          ...this.dataUser,
        });
        var newPatId = response.patient.patient_id;
      } else {
        var response = await this.api.post("patients", this.addingList);
        var newPatId = response.patient_id;
      }
      if (response != null) {
        window.PineconeRouter.context.navigate(`/patient/${newPatId}`);
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}
