import { DataModel } from "./DataModel";

export class Physicians extends DataModel {
  constructor(data) {
    super(data);
    this.sortBy = ["surname"];
    this.sortByNames = ["Name"];
    this.initSort = [true];
    this.dateTimeFields = ["created_at"];
    this.canSwitchSortOrder = false;
    this.filteringAttrs = ["realm"];
    this.editable = [
      "surname",
      "given_name",
      "titles",
      "realm",
      "email",
      "clinics",
      "patients",
      "connected_user",
      "phone",
    ];

    this.rowsPerPage = 10;
  }

  init() {
    this.data = this.data.map((item) => {
      const numClinics = item.clinics.length;
      const clinics = item.clinics
        .map((obj) => `${obj["name"]} (${obj["ambulance"]})`)
        .join(" \\ ");
      const numPatients = item.patients.length;
      const username = item.connected_user?.username ?? null;
      return {
        ...item,
        ["numClinics"]: numClinics,
        ["clinicsStr"]: clinics,
        ["numPatients"]: numPatients,
        ["username"]: username,
      };
    });
    super.init();
  }
}
