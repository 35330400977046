import { DataModel } from "./DataModel";

export class Events extends DataModel {
  // constants
  eventTypes = {
    patient_contact: "Patient contact",
    medical_call: "Medical call",
    medication_change: "Medication change",
    check_up: "Check-up",
    labs: "Labs",
    hospitalization: "Hospitalization",
    patient_non_adherence: "Patient non-adherence",
    physician_notified: "Treating physician notified",
  };

  constructor(data) {
    super(data);
    this.sortBy = ["event_date", "event_timestamp"];
    this.sortByNames = ["Date", "Timestamp"];
    this.dateTimeFields = ["event_timestamp"];
    this.dateFields = ["event_date"];
    this.stringFields = ["event_description", "event_comment"];
    this.editable = ["event_description", "event_comment"];
    this.initSort = [true, null];
    this.filteringAttrs = ["event_type"];
    this.canSwitchSortOrder = true;
  }
}
