import { ApiConnector } from "../apiConnector";
import { DataAdd } from "./DataAdd";

export class AddClinic extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      name: null,
      ambulance: null,
      address: null,
      country: null,
      realm: 1,
      phone: null,
      email: null,
      physicians_uuids: [],
    };
  }

  async initEmpty() {}

  async post(physicians: string[] = []) {
    this.addingList["physicians_uuids"] = physicians;
    try {
      const response = await this.api.post("clinics", this.addingList);
      if (response != null) {
        window.PineconeRouter.context.navigate("/admin/clinics");
        location.reload();
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}
