import { ApiConnector } from "./apiConnector";
import { ListDataField } from "./dataAdd/ListDataField";
import { Physicians } from "./dataModels/Physicians";
import { Clinics } from "./dataModels/Clinics";
import { Users } from "./dataModels/Users";
import { Patients } from "./dataModels/Patients";
import { ModalWindow } from "./modals";

export class AdminConsole {
  clinicsModal: ModalWindow = null;
  physiciansModal: ModalWindow = null;
  patientsModal: ModalWindow = null;
  usersModal: ModalWindow = null;

  addingClinic = false;
  createClinicText = "Create new clinic";
  addingPhysician = false;
  createPhysicianText = "Create new physician";
  addingUser = false;
  createUserText = "Create new user";

  physicianPhone: ListDataField = new ListDataField();

  constructor(
    protected api: ApiConnector,
    public clinics: Clinics,
    public physicians: Physicians,
    public patients: Patients,
    public users: Users,
  ) {}

  async init() {
    this.clinicsModal = new ModalWindow(this.clinics);
    await this.clinicsModal.init("/static/templates/modals/clinics.html");
    this.physiciansModal = new ModalWindow(this.physicians);
    await this.physiciansModal.init("/static/templates/modals/physicians.html");
    this.patientsModal = new ModalWindow(this.patients);
    await this.patientsModal.init("/static/templates/modals/patients.html");
    this.usersModal = new ModalWindow(this.users);
    await this.usersModal.init("/static/templates/modals/users.html");
  }

  resetModals() {
    this.clinicsModal.resetModal();
    this.physiciansModal.resetModal();
    this.patientsModal.resetModal();
    this.usersModal.resetModal();
  }

  // creating data
  toggleCreateClinic() {
    this.addingClinic = !this.addingClinic;
    if (this.addingClinic) {
      this.createClinicText = "Cancel";
    } else {
      this.createClinicText = "Create new clinic";
    }
  }

  toggleCreatePhysician() {
    this.addingPhysician = !this.addingPhysician;
    if (this.addingPhysician) {
      this.createPhysicianText = "Cancel";
    } else {
      this.createPhysicianText = "Create new physician";
    }
  }

  toggleCreateUser() {
    this.addingUser = !this.addingUser;
    if (this.addingUser) {
      this.createUserText = "Cancel";
    } else {
      this.createUserText = "Create new user";
    }
  }

  // users
  chooseConnectedPatientForUser() {
    this.patientsModal.modalText = "Choose connected ID for a new user";
    this.patientsModal.openModal();
  }

  chooseManagingPatientsForUser() {
    this.patientsModal.modalText =
      "Choose managed patient for a new managing user";
    this.patientsModal.chooseSingle = false;
    this.patientsModal.openModal();
  }

  userConnectedPatientDefault(index: number) {
    return this.users.getEditedField(index)["connected_patient_id"];
  }

  async editConnectedPatientForUser(index: number) {
    this.patientsModal.setSingleChoice(
      index,
      await this.userConnectedPatientDefault(index),
      "Change connected ID for user: " +
        this.users.paginatedData[index].username,
    );
  }

  async currentConnectedPatientsForUser(index: number) {
    return this.patientsModal.finalSingleChoice
      ? this.patientsModal.finalSingleChoice
      : await this.userConnectedPatientDefault(index);
  }

  editManagingPatientsForUser(index: number) {
    const edited = this.users.getEditedField(index);
    const managed = edited["managed_patients"];
    this.patientsModal.setMultiChoice(
      index,
      managed.map((item) => item["patient_id"]),
      "Change managing patients for user: " +
        this.users.paginatedData[index].username,
    );
  }

  numberOfManagingPatients(index: number) {
    const edited = this.users.getEditedField(index);
    const managedNum = edited.hasOwnProperty("managed_patients")
      ? edited["managed_patients"].length
      : 0;
    const num = this.patientsModal.modalChange
      ? this.patientsModal.finalMultiChoice.length
      : managedNum;
    return num + " chosen";
  }

  // clinics
  choosePhysiciansForClinic(realm: number) {
    this.physiciansModal.modalData.filter["realm"] = [realm];
    this.physiciansModal.modalText = "Choose physicians for a new clinic";
    this.physiciansModal.chooseSingle = false;

    this.physiciansModal.openModal();
  }

  editPhysiciansForClinic(index: number) {
    const edited = this.clinics.getEditedField(index);
    const physicians = edited["physicians"];
    this.physiciansModal.modalData.filter["realm"] = [edited["realm"]];
    this.physiciansModal.setMultiChoice(
      index,
      physicians.map((item) => item["uuid"]),
      "Change physicians for clinic: " + this.clinics.paginatedData[index].name,
    );
  }

  currentPhysiciansForClinic(index: number) {
    const num = this.physiciansModal.modalChange
      ? this.physiciansModal.finalMultiChoice.length
      : this.clinics.getEditedField(index)["physicians"].length;
    return num + " chosen";
  }

  // physicians
  chooseConnectedUserForPhysician() {
    this.usersModal.modalData.filter["role"] = ["study-physician", "physician"];
    this.usersModal.modalText = "Choose connected user for a new physician";
    this.usersModal.openModal();
  }
  chooseClinicsForPhysician(realm: number) {
    this.clinicsModal.modalData.filter["realm"] = [realm];
    this.clinicsModal.modalText = "Choose clinics for a new physician";
    this.clinicsModal.chooseSingle = false;

    this.clinicsModal.openModal();
  }

  choosePatientsForPhysician(realm: number) {
    this.patientsModal.modalData.filter["realm"] = [realm];
    this.patientsModal.modalText = "Choose patients for a new physician";
    this.patientsModal.chooseSingle = false;
    this.patientsModal.openModal();
  }

  editClinicsForPhysician(index: number) {
    const edited = this.physicians.getEditedField(index);
    const clinics = edited["clinics"];
    this.clinicsModal.modalData.filter["realm"] = [edited["realm"]];
    this.clinicsModal.setMultiChoice(
      index,
      clinics.map((item) => item["uuid"]),
      "Change clinics for physician: " +
        this.physicians.paginatedData[index].surname +
        " " +
        this.physicians.paginatedData[index].given_name,
    );
  }

  currentClinicsForPhysician(index: number) {
    const num = this.clinicsModal.modalChange
      ? this.clinicsModal.finalMultiChoice.length
      : this.physicians.getEditedField(index)["clinics"].length;
    return num + " chosen";
  }

  editPatientsForPhysician(index: number) {
    const edited = this.physicians.getEditedField(index);
    const patients = edited["patients"];
    this.patientsModal.modalData.filter["realm"] = [edited["realm"]];
    this.patientsModal.setMultiChoice(
      index,
      patients.map((item) => item["patient_id"]),
      "Change patients for physician: " +
        this.physicians.paginatedData[index].surname +
        " " +
        this.physicians.paginatedData[index].given_name,
    );
  }

  currentPatientsForPhysician(index: number) {
    const num = this.patientsModal.modalChange
      ? this.patientsModal.finalMultiChoice.length
      : this.physicians.getEditedField(index)["patients"].length;
    return num + " chosen";
  }

  physicianConnectedUserDefault(index: number) {
    const connectedUser =
      this.physicians.getEditedField(index)["connected_user"];
    return connectedUser?.uuid ?? null;
  }

  async currentConnectedUserForPhysician(index: number) {
    return this.usersModal.finalSingleChoice
      ? this.usersModal.finalSingleChoice
      : await this.physicianConnectedUserDefault(index);
  }

  async currentConnectedUsernameForPhysician(index: number) {
    const uuid = await this.currentConnectedUserForPhysician(index);
    return this.users.userMapping[uuid];
  }

  async editConnectedUserForPhysician(index: number) {
    this.usersModal.modalData.filter["role"] = ["study-physician", "physician"];
    this.usersModal.setSingleChoice(
      index,
      await this.physicianConnectedUserDefault(index),
      "Change connected user for physician: " +
        this.physicians.paginatedData[index].surname +
        " " +
        this.physicians.paginatedData[index].given_name,
    );
  }

  // updates
  async updateUser(uuid: string, body: object) {
    const userBody = {
      preferred_language: body["preferred_language"],
      connected_patient_id: this.patientsModal.finalSingleChoice,
      managed_patient_ids: Object.values(this.patientsModal.finalMultiChoice),
    };
    await this.api.put(`users/${uuid}`, userBody);
    await this.users.init();
    window.PineconeRouter.context.navigate(`/admin/users`);
    window.location.reload();
  }

  async resetUser(uuid: string) {
    await this.api.get(`users/reset/${uuid}?reset_email=true`);
    window.PineconeRouter.context.navigate(`/admin/users`);
    window.location.reload();
  }

  async updateClinic(uuid: string, body: object) {
    body["physicians"] = this.physiciansModal.finalMultiChoice;
    await this.api.put(`clinics/${uuid}`, body);
    this.clinics.init();
    window.PineconeRouter.context.navigate(`/admin/clinics`);
    window.location.reload();
  }

  async updatePhysician(uuid: string, body: object) {
    body["connected_user_uuid"] = this.usersModal.finalSingleChoice;
    body["clinics"] = this.clinicsModal.finalMultiChoice;
    body["patients"] = this.patientsModal.finalMultiChoice;
    await this.api.put(`physicians/${uuid}`, body);
    this.physicians.init();
    window.PineconeRouter.context.navigate(`/admin/physicians`);
    window.location.reload();
  }
}
