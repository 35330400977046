import { DataModel } from "./DataModel";

export class PPGs extends DataModel {
  toQuickLook = [];

  constructor(data) {
    super(data);
    this.sortBy = ["measurement_datetime"];
    this.sortByNames = ["Measured"];
    this.dateTimeFields = ["measurement_datetime"];
    this.editable = [
      "measurement_condition",
      "measurement_mode",
      "quality_flag",
      "comment",
    ];
    this.initSort = [true];
    this.filteringAttrs = [
      "measurement_condition",
      "measurement_device",
      "measurement_origin",
      "measurement_mode",
      "quality_flag",
    ];
  }

  toggleQuickLook(uuid) {
    if (this.toQuickLook.includes(uuid)) {
      this.toQuickLook = this.toQuickLook.filter((p) => p !== uuid);
    } else {
      this.toQuickLook.push(uuid);
    }
  }

  selectAllQuickLook() {
    const filtered = this.sortFilterData();
    this.toQuickLook = filtered.map((obj) => obj.uuid);
  }

  clearQuickLook() {
    this.toQuickLook = [];
  }
}
