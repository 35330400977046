import { DataModel } from "./DataModel";

export class Diagnoses extends DataModel {
  constructor(data, canShowHistory = false) {
    super(data, canShowHistory);
    this.hasHistory = true;
    this.sortBy = ["diagnosed_at", "diagnosis_name"];
    this.sortByNames = ["Diagnosed", "Name"];
    this.dateFields = ["diagnosed_at", "remission"];
    this.dateTimeFields = ["created_at"];
    this.stringFields = ["diagnosis_name"];
    this.editable = [
      "diagnosed_at",
      "diagnosis_value",
      "diagnosis_confidence",
      "remission",
      "comment",
    ];
    this.initSort = [true, null];
  }
}
