import { DataModel } from "./DataModel";

export class Symptoms extends DataModel {
  constructor(data, canShowHistory = false) {
    super(data, canShowHistory);
    this.hasHistory = true;
    this.sortBy = ["symptom_date", "symptom_name"];
    this.sortByNames = ["Started", "Symptom"];
    this.dateFields = ["symptom_date", "symptom_change_date"];
    this.dateTimeFields = ["created_at"];
    this.stringFields = ["symptom_name"];
    this.editable = [
      "symptom_date",
      "symptom_name",
      "symptom_value",
      "symptom_change_in_last_six_m",
      "symptom_change_date",
      "symptom_value_before",
      "comment",
    ];
    this.initSort = [true, null];
    this.canSwitchSortOrder = true;
  }
}
