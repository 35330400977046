import { AlertType } from "../api";
import { DataModel } from "./DataModel";

export class Alerts extends DataModel {
  // constants
  alertTypes: Record<AlertType, string> = {
    [AlertType.low_critical]: "CRITICAL low",
    [AlertType.low_alert]: "Alert low",
    [AlertType.low_warning]: "warning low",
    [AlertType.normal]: "normal",
    [AlertType.high_warning]: "warning high",
    [AlertType.high_alert]: "Alert high",
    [AlertType.high_critical]: "CRITICAL high",
  };

  constructor(data) {
    super(data);
    this.sortBy = ["patient_id", "alert_timestamp"];
    this.sortByNames = ["Patient", "Timestamp"];
    this.dateTimeFields = ["alert_timestamp", "created_at"];
    this.initSort = [null, true];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = ["alert_variable", "alert_type", "alert_seen"];
    this.filteringListAttrs = ["alert_tags"];
  }
}
